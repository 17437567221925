import axios from 'axios';

const buildHeaders = (headers = {}, token = null) => {
  const reqHeaders = { ...headers };

  if (token && !reqHeaders.Authorization) {
    reqHeaders.Authorization = `Bearer ${token}`;
  }

  return reqHeaders;
};

const handleError = (error) => {
  if (error.response) {
    throw new Error(`Failed response. Status: ${error.response.status} ${error.response.statusText}`);
  } else if (error.request) {
    throw new Error('Network error. No response received.');
  } else {
    throw new Error(`Error: ${error.message}`);
  }
};

const getJSON = (url, token = null, headersMap = {}) =>
  axios
    .get(url, { headers: buildHeaders(headersMap, token) })
    .then((response) => response.data)
    .catch(handleError);

const postJSON = (url, body, token = null, headersMap = {}) =>
  axios
    .post(url, body, { headers: buildHeaders(headersMap, token) })
    .then((response) => response.data)
    .catch(handleError);

const putJSON = (url, body, token = null, headersMap = {}) =>
  axios
    .put(url, body, { headers: buildHeaders(headersMap, token) })
    .then((response) => response.data)
    .catch(handleError);

const putFile = (url, body, token = null, headersMap = {}) =>
  axios
    .put(url, body, { headers: buildHeaders(headersMap, token) })
    .then((response) => response.data)
    .catch(handleError);

const deleteJSON = (url, token = null, headersMap = {}) =>
  axios
    .delete(url, { headers: buildHeaders(headersMap, token) })
    .then(() => {})
    .catch(handleError);

const postFile = (url, body, token = null, headersMap = {}) =>
  axios
    .post(url, body, { headers: buildHeaders(headersMap, token) })
    .then((response) => response.data)
    .catch(handleError);

export default {
  getJSON,
  postJSON,
  putJSON,
  deleteJSON,
  putFile,
  postFile,
};
